import React from 'react'

import { WithStyles, withStyles } from '@material-ui/styles'
import {
  Typography,
  Paper,
  createStyles,
  Grid,
  TextField,
  Divider,
} from '@material-ui/core'
import theme from '../../../theme'

import { getReadableTelemetryStatus } from 'components/utils/converter'
import { getStatusLensColor } from '../../utils'
import { TelemetryType, StatusRange, TelemetryStatus } from 'api/alertservice'
import {
  formatActualTankLevelFeet,
  formatTelemetryTypeAndUnit,
} from 'components/utils/formatter'

const styles = () =>
  createStyles({
    helperText: {
      margin: '4px 0',
      fontSize: '0.7rem',
    },
  })

export interface Props {
  readonly type: TelemetryType
  readonly statusRanges: StatusRange[]
}

type AllProps = Props & WithStyles<typeof styles>

type EditableStatusThreshold = Partial<StatusRange>

class TelemetryThresholds extends React.Component<AllProps> {
  private readonly thresholds: Map<TelemetryStatus, EditableStatusThreshold[]>

  private readonly supportedTelemetryStatuses = Object.values(
    TelemetryStatus
  ).filter(
    (status) =>
      typeof status !== 'number' &&
      status !== TelemetryStatus[TelemetryStatus.Undefined]
  )

  constructor(props: AllProps) {
    super(props)

    this.thresholds = new Map<TelemetryStatus, EditableStatusThreshold[]>()
    props.statusRanges.forEach((range) => {
      const thresholdsPerStatus = this.thresholds.get(range.status)
      if (thresholdsPerStatus !== undefined) {
        thresholdsPerStatus.push(range)
      } else {
        const statusThresholds = Array<EditableStatusThreshold>(range)
        this.thresholds.set(range.status, statusThresholds)
      }
    })

    this.supportedTelemetryStatuses.forEach((statusName) => {
      const status = TelemetryStatus[statusName]
      const thresholdsForConcreteStatus = this.thresholds.get(status)
      if (thresholdsForConcreteStatus === undefined) {
        this.thresholds.set(status, [] as EditableStatusThreshold[])
      }
    })
  }

  public render() {
    return (
      <Grid item={true}>
        <div style={{ padding: theme.spacing(3) }}>
          <Paper
            elevation={8}
            square={true}
            style={{ padding: theme.spacing(1) }}
          >
            <div
              style={{
                paddingLeft: theme.spacing(0.5),
                paddingRight: theme.spacing(1.5),
              }}
            >
              <div
                style={{
                  display: 'flex',
                  padding: '5px 15px',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h6">
                  {formatTelemetryTypeAndUnit(this.props.type)}
                </Typography>
              </div>
              <Grid
                container={true}
                style={{
                  justifyContent: 'space-between',
                }}
              >
                {this.renderThresholdsForAllStatuses()}
              </Grid>
            </div>
          </Paper>
        </div>
      </Grid>
    )
  }

  private readonly renderThresholdsForAllStatuses = () => {
    return this.supportedTelemetryStatuses.map((telemetryStatus) => {
      return this.renderThresholdsForSpecificStatus(
        TelemetryStatus[telemetryStatus]
      )
    })
  }

  private readonly renderThresholdsForSpecificStatus = (
    status: TelemetryStatus
  ) => {
    const statusName = TelemetryStatus[status]
    const telemetryTypeName = TelemetryType[this.props.type]

    const statusThresholds = this.thresholds.get(status)
    return (
      <Grid
        item={true}
        xs={12}
        md={4}
        lg={4}
        key={status}
        style={{
          maxWidth: '290px',
          paddingTop: theme.spacing(1.9),
          paddingBottom: theme.spacing(1.5),
        }}
      >
        <div
          style={{
            padding: '15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <div
            style={{
              backgroundColor: getStatusLensColor(status),
              borderRadius: '50%',
              width: theme.spacing(3),
              height: theme.spacing(3),
            }}
          />
          <Typography style={{ marginLeft: '5px' }}>
            {getReadableTelemetryStatus(status)}
          </Typography>
        </div>
        <Divider variant="middle" style={{ marginBottom: '5px' }} />
        {this.thresholds.get(status)!.length > 0 && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '5px 15px 0',
              color: 'grey',
            }}
          >
            <Typography
              style={{
                width: '90px',
                padding: '0 5px',
              }}
            >
              From
            </Typography>
            <Typography
              style={{
                width: '90px',
                marginLeft: '10px',
                padding: '0 5px',
              }}
            >
              To
            </Typography>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0px 15px',
          }}
        >
          {statusThresholds?.map((threshold, index) => {
            return (
              <div
                key={index}
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                }}
              >
                <TextField
                  name={`${telemetryTypeName}-${statusName}-${index}-From`}
                  variant="outlined"
                  size="small"
                  value={this.getFormattedValue(
                    this.props.type,
                    threshold.minThreshold
                  )}
                  placeholder="——"
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{
                    width: '90px',
                    marginTop: '5px',
                  }}
                  FormHelperTextProps={{
                    className: this.props.classes.helperText,
                  }}
                />
                <TextField
                  name={`${telemetryTypeName}-${statusName}-${index}-To`}
                  variant="outlined"
                  size="small"
                  value={this.getFormattedValue(
                    this.props.type,
                    threshold.maxThreshold
                  )}
                  placeholder="——"
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{
                    width: '90px',
                    marginLeft: '10px',
                    marginTop: '5px',
                  }}
                  FormHelperTextProps={{
                    className: this.props.classes.helperText,
                  }}
                />
              </div>
            )
          })}
        </div>
        {this.thresholds.get(status)?.length === 0 && (
          <Typography
            style={{
              padding: '25px 15px',
              textAlign: 'center',
            }}
          >
            No thresholds defined
          </Typography>
        )}
      </Grid>
    )
  }

  private readonly getFormattedValue = (
    type: TelemetryType,
    value?: number
  ) => {
    switch (type) {
      case TelemetryType.TankLevel:
        return formatActualTankLevelFeet(value)
      case TelemetryType.CasingPressure:
      case TelemetryType.TubingPressure:
      case TelemetryType.PumpPressure:
      case TelemetryType.HeaterTemp:
      case TelemetryType.Flow:
      case TelemetryType.StaticPressure:
      case TelemetryType.SeparatorPressure:
      case TelemetryType.CompressorPressure:
      case TelemetryType.TurbineMeter:
      case TelemetryType.GenericSensor:
      case TelemetryType.StrokesPerMinute:
      case TelemetryType.KnockoutPressure:
        return value
      default:
        return undefined
    }
  }
}

export default withStyles(styles)(TelemetryThresholds)
