import * as React from 'react'
import { connect } from 'react-redux'
import {
  RouteComponentProps,
  generatePath,
  Link as RouterLink,
  withRouter,
} from 'react-router-dom'
import moment from 'moment'
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  CircularProgress,
  Grid,
  Link,
  Typography,
} from '@material-ui/core'
import { AppState } from '../../store/AppState'
import {
  getSensorClient,
  loadMostRecentValue,
  getGatewaysClient,
} from 'api/client'
import { ModelState } from 'store/ModelState'
import {
  DoubleRowTableColumnHeader,
  DoubleRowTableCell,
  StyledTableCell as TableCell,
} from 'components/misc/Components'
import { Device } from 'store/Device'
import { SiteMatch } from '../SiteDetails'
import { deviceRoute, siteRoute, idRoute, telemetryTypeRoute } from 'App'
import {
  SensorUseCase,
  TelemetryType,
  GatewayResponse,
} from '../../api/apiservice'
import { SitesState } from 'store/Site'
import { requestSite, SiteState } from 'store/SiteDetails'
import { UiContextData, withUiContext } from 'components/contexts/UiContext'
import { isAdmin, isSystemAdmin } from '../../store/oidc/userManager'

interface PropsFromState {
  readonly accessToken?: string
  readonly tenantId: number
  readonly sites: SitesState
  readonly userId?: string
  readonly user: MultitenantUserState
}

interface State extends ModelState {
  readonly sensors: Device[]
  readonly gateways: GatewayResponse[]
  readonly loadingBatteryLevel?: boolean
  readonly site: SiteState
}

interface Props {
  readonly setIsPlungerTabVisible: (value: boolean) => void
  readonly setIsPumpControlTabVisible: (value: boolean) => void
}

type AllProps = Props &
  PropsFromState &
  RouteComponentProps<SiteMatch> &
  UiContextData

class DevicesTab extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props)
    const {
      sites: { sites },
    } = this.props
    this.state = {
      sensors: [],
      gateways: [],
      site: {
        site: sites.find((w) => w.id === props.match.params.id),
      } as SiteState,
    }
  }

  public componentDidMount() {
    this.load()
  }

  public componentDidUpdate({ match, accessToken }: AllProps) {
    if (
      accessToken !== this.props.accessToken ||
      match.params.id !== this.props.match.params.id
    ) {
      this.load()
    }
  }

  public render() {
    if (this.state.gateways.length > 0) {
      return (
        <>
          <Grid
            container={true}
            style={{ maxHeight: '500px', overflow: 'auto' }}
          >
            <Grid item={true} xs={12}>
              {this.renderTable()}
            </Grid>
          </Grid>
          <Typography
            color="primary"
            variant="h6"
            align="center"
            style={{ maxHeight: '500px', overflow: 'auto' }}
          >
            GATEWAYS
          </Typography>
          <Grid container={true}>
            <Grid item={true} xs={12}>
              {this.renderGatewayTable()}
            </Grid>
          </Grid>
        </>
      )
    } else {
      return (
        <>
          <Grid
            container={true}
            style={{ maxHeight: '500px', overflow: 'auto' }}
          >
            <Grid item={true} xs={12}>
              {this.renderTable()}
            </Grid>
          </Grid>
        </>
      )
    }
  }

  public renderTable() {
    const path = siteRoute + idRoute + deviceRoute + telemetryTypeRoute

    return (
      <TableContainer component={Paper}>
        <Table size="small" stickyHeader={true}>
          <TableHead>
            <TableRow>
              <TableCell>
                <DoubleRowTableColumnHeader title="Sensor ID" />
              </TableCell>
              <TableCell>
                <DoubleRowTableColumnHeader title="Use Case" />
              </TableCell>
              <TableCell>
                <DoubleRowTableColumnHeader title="Model Name" />
              </TableCell>
              <TableCell>
                <DoubleRowTableColumnHeader title="Gateway Id" />
              </TableCell>
              <TableCell>
                <DoubleRowTableColumnHeader title="Asset Id" />
              </TableCell>
              <TableCell>
                <DoubleRowTableColumnHeader title="Provision Date" />
              </TableCell>
              <TableCell>
                <DoubleRowTableColumnHeader
                  title="Battery Level"
                  caption="updated at"
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.sensors.map((s, i) => (
              <TableRow key={i}>
                <TableCell align="left">
                  <p
                    style={{
                      margin: 0,
                      padding: '0.5em 0 0.5em 0',
                    }}
                  >
                    <Link
                      component={RouterLink}
                      to={generatePath(path, {
                        id: s.siteId,
                        deviceId: s.id,
                        telemetryType:
                          TelemetryType.SensorBatteryLevel.toString(),
                      })}
                    >
                      {s.id}
                    </Link>
                  </p>
                </TableCell>
                <TableCell align="center">{SensorUseCase[s.useCase]}</TableCell>
                <TableCell align="center">{s.modelName}</TableCell>
                <TableCell align="center">{s.gatewayId}</TableCell>
                <TableCell align="center">
                  {s.channels === undefined || s.channels[0] === undefined
                    ? ''
                    : s.channels[0].assetId}
                </TableCell>
                <TableCell align="center">
                  {moment(s.createdAt).format('lll')}
                </TableCell>
                {this.state.loadingBatteryLevel ? (
                  <TableCell>
                    <CircularProgress size="1rem" />
                  </TableCell>
                ) : (
                  <DoubleRowTableCell
                    align="center"
                    first={`${s.batteryLevel} %` ?? 'N/A'}
                    second={
                      s.batteryLevelUpdateTimestamp !== undefined
                        ? moment(s.batteryLevelUpdateTimestamp).fromNow()
                        : undefined
                    }
                  />
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  public renderGatewayTable() {
    const gatewayPath = siteRoute + idRoute + deviceRoute + telemetryTypeRoute
    return (
      <TableContainer component={Paper}>
        <Table size="small" stickyHeader={true}>
          <TableHead>
            <TableRow>
              <TableCell>
                <DoubleRowTableColumnHeader title="Gateway ID" />
              </TableCell>
              <TableCell>
                <DoubleRowTableColumnHeader title="Model Name" />
              </TableCell>
              <TableCell>
                <DoubleRowTableColumnHeader title="Provision Date" />
              </TableCell>
              <TableCell>
                <DoubleRowTableColumnHeader
                  title="Battery Level"
                  caption="updated at"
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.gateways.map((s, i) => (
              <TableRow key={i}>
                <TableCell align="center">
                  <p
                    style={{
                      margin: 0,
                      padding: '0.5em 0 0.5em 0',
                    }}
                  >
                    <Link
                      component={RouterLink}
                      to={generatePath(gatewayPath, {
                        id: s.siteId,
                        deviceId: s.id,
                        telemetryType: TelemetryType.GatewayMetrics.toString(),
                      })}
                    >
                      {s.id}
                    </Link>
                  </p>
                </TableCell>

                <TableCell align="center">{s.modelName}</TableCell>

                <TableCell align="center">
                  {moment(s.createAt).format('lll')}
                </TableCell>
                {this.state.loadingBatteryLevel ? (
                  <TableCell>
                    <CircularProgress size="1rem" />
                  </TableCell>
                ) : (
                  <DoubleRowTableCell
                    align="center"
                    first={`${s.batteryLevel} %` ?? 'N/A'}
                    second={
                      s.lastReportTime !== undefined
                        ? moment(s.lastReportTime).fromNow()
                        : undefined
                    }
                  />
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  private async load() {
    const { accessToken, tenantId } = this.props

    if (!accessToken || !tenantId) {
      return
    }

    const { id } = this.props.match.params

    try {
      this.setState({ loading: true })

      this.loadSite(accessToken, tenantId.toString() || '', id)

      const api = getSensorClient(accessToken, tenantId.toString())
      const { result } = await api.getSensors(id)
      const sensors = result.map((s) => ({ ...s.toJSON() } as Device))

      const gateways = await this.getGateways(
        accessToken,
        tenantId.toString(),
        id
      )

      this.setState({ sensors, gateways, error: undefined }, () =>
        this.loadBatteryLevel()
      )
    } catch (error) {
      this.setState({ error })
    } finally {
      this.setState({ loading: false })
    }
  }

  private async getGateways(accessToken, tenantId, siteId) {
    const apiGateways = getGatewaysClient(accessToken, tenantId.toString())
    const { result } = await apiGateways.getGateway(siteId)
    const gateways = result.map((t) => ({ ...t.toJSON() } as GatewayResponse))

    return gateways
  }

  private async loadSite(
    accessToken: string,
    tenantId: string,
    siteId: string
  ) {
    await requestSite(
      accessToken,
      tenantId,
      siteId,
      () => this.state.site,
      (site) =>
        !site.loading &&
        this.setState({ site: site }, () => {
          this.props.setPageTitle!(site.site?.name ?? site.site?.id)
          const isPumpControlTabVisible =
            this.state.site.site?.pumpControls &&
            this.state.site.site.pumpControls.length > 0 &&
            isAdmin(this.props.user.selectedTenant?.roles)

          if (this.state.site.site?.plungerLift) {
            this.props.setIsPlungerTabVisible(true)
          }

          this.props.setIsPumpControlTabVisible(isPumpControlTabVisible)
        })
    )
  }

  private async loadBatteryLevel() {
    const { accessToken, tenantId } = this.props

    if (!accessToken || !tenantId) {
      return
    }

    try {
      this.setState({ loadingBatteryLevel: true })

      const promises = this.state.sensors.map(async (s) => {
        try {
          const batteryLevel = await loadMostRecentValue(
            accessToken,
            tenantId.toString(),
            s.siteId!,
            null,
            TelemetryType.SensorBatteryLevel,
            s.id
          )
          const sensors = this.state.sensors.map((sensor) =>
            sensor.id === s.id
              ? ({
                  ...sensor,
                  batteryLevel: batteryLevel?.value,
                  batteryLevelUpdateTimestamp: batteryLevel?.timestamp,
                } as Device)
              : sensor
          )

          this.setState({ sensors })
        } catch (error) {
          console.error(`Failed to retrieve battery level for '${s.id}'.`)
        }
      })

      await Promise.all(promises)
    } catch (error) {
      this.setState({ error })
    } finally {
      this.setState({
        loadingBatteryLevel: false,
      })
    }
  }
}

const mapPropsFromState = ({ oidc, sites, multitenantUser }: AppState) => ({
  sites: sites,
  accessToken: multitenantUser.accessToken,
  tenantId: multitenantUser.tenants?.find((t) => t.selected)?.id || 0,
  userId: multitenantUser.id,
  user: multitenantUser,
})

export default withRouter(withUiContext(connect(mapPropsFromState)(DevicesTab)))
