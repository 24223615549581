import * as React from 'react'
import {
  TelemetryType,
  StatisticsClient,
  TelemetryAverageResponse,
} from '../../api/apiservice'
import BaseSensor from './BaseSensor'
import {
  getTimeRange,
  getTimeRangeDuration,
  TimeRange,
} from 'components/misc/TimeRange'
import moment, { duration } from 'moment'
import { multitenantApiBasePath, getFetch } from 'api/fetch'
import { Reference } from './SensorChart'

export default class Flow extends BaseSensor {
  constructor(props) {
    super(
      props,
      props.asset.isTotalFlow ? TelemetryType.TotalFlow : TelemetryType.Flow,
      props.asset.isTotalFlow ? 'Total Flow' : 'Flow'
    )

    this.loadReferences = this.loadReferences.bind(this)
  }

  protected async loadReferences(refTimeRange?: TimeRange) {
    const {
      accessToken,
      tenantId,
      timeRange,
      site: { id },
    } = this.props

    if (this.TelemetryType !== TelemetryType.TotalFlow) {
      try {
        const selectedRange = refTimeRange ?? getTimeRange(timeRange)
        const timeRangeDuration = getTimeRangeDuration(timeRange).add(1, 'day')
        const minPeriod = Math.min(
          duration(4, 'days').asMilliseconds(),
          timeRangeDuration.asMilliseconds()
        )

        const selectedEndDate = selectedRange[1]
        const toDateTimeUtc = moment(selectedEndDate).utc().toDate()
        const fromDateTimeUtc = moment(selectedEndDate)
          .subtract(minPeriod)
          .utc()
          .toDate()

        const {
          result: { averages },
        } = await new StatisticsClient(
          multitenantApiBasePath,
          getFetch(accessToken, tenantId.toString())
        ).getFlowAverages(id, fromDateTimeUtc, toDateTimeUtc)

        const references = (averages ?? [])
          .map(this.mapRef)
          .filter((r) => r !== null) as Reference[]

        this.setState({
          references,
          referenceName: 'previous day average',
        })
      } catch (error) {
        console.warn(
          `Failed to load averages for ${TelemetryType[this.TelemetryType]}`,
          error
        )
      }
    }
  }

  private readonly mapRef = ({
    value,
    fromUtc,
    toUtc,
  }: TelemetryAverageResponse): Reference | null => {
    const [fromDate, toDate] = getTimeRange(this.props.timeRange)
    const [from, to] = [
      fromDate?.valueOf() ?? 0,
      (toDate ?? Date.now()).valueOf(),
    ]
    const refFromLocal = moment.utc(fromUtc).local()
    let refFrom = refFromLocal.add(1, 'day').valueOf()
    const refToLocal = moment.utc(toUtc).local()
    let refTo = refToLocal.add(1, 'day').valueOf()

    if (refTo <= from || refFrom >= to) {
      return null
    }
    if (refFrom < from) {
      refFrom = from
    }
    if (refTo > to) {
      refTo = to
    }

    const ref: Reference = {
      value,
      from: new Date(refFrom),
      to: new Date(refTo),
    }
    return ref
  }

  protected formatValue = (value?: any, timeStamp?: Date): React.ReactNode => {
    let isTotalFlow

    if (typeof value === 'object') {
      value = value?.volumeFlowRate || 0
      isTotalFlow = true
    }

    if (typeof value !== 'number') {
      return 'N/A'
    }

    const { references } = this.state
    const time = timeStamp?.valueOf()
    const pda = time
      ? references?.find(
          (r, i) =>
            r.from.valueOf() <= time &&
            (i === references.length - 1 || time <= r.to.valueOf())
        )
      : undefined

    return (
      <React.Fragment>
        {this.renderValue(
          isTotalFlow ? 'Total Flow' : 'Flow',
          value.toFixed(1)
        )}
        {pda && this.renderValue('Prev. day avg.', pda.value.toFixed(1))}
      </React.Fragment>
    )
  }

  protected getDisplayReading(readingValue?: number): number | undefined {
    return readingValue
  }
}
