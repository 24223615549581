import React from 'react'
import { connect } from 'react-redux'
import {
  generatePath,
  Link as RouterLink,
  withRouter,
  RouteComponentProps,
} from 'react-router-dom'
import moment from 'moment'
import memoize from 'memoize-one'
import ReactScrollIntoViewIfNeeded from 'react-scroll-into-view-if-needed'
import {
  setSitesTableSortingOrderBy,
  setSitesTableSortingOrderDirection,
  getSitesTableOrderByColumnObj,
  getSitesTableOrderDirectionObj,
  TableOrderDirectionType,
  SitesTableOrderByType,
} from 'utils/localStorage'

import {
  Theme,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Link,
  Tooltip,
  Zoom,
  LinearProgress,
  TableSortLabel,
  TableCellProps,
  TableCell,
  SvgIconProps,
  Collapse,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Button,
  TableFooter,
  Typography,
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import { WithStyles, createStyles, withStyles } from '@material-ui/styles'
import theme from 'theme'

import { TelemetryType } from '../../api/apiservice'
import { SiteContextData, withSiteContext } from '../contexts/SiteContext'
import { AppState } from '../../store/AppState'
import {
  Tank,
  Site,
  BalancedTank,
  GatewayMetricsAsSensor,
} from '../../store/Site'
import { Group } from '../../store/Group'
import { TelemetryMessage } from 'store/SiteDetails'
import { getLatestTelemetry } from 'api/client'
import { ModelState } from '../../store/ModelState'
import { getStatusLensColor, getAssetFilterArray } from '../utils/index'
import {
  feetToFeetAndInches,
  inchToBbl,
  formatValue,
  formatValueTotalFlow,
  getReadableProductionCode,
  getReadableTelemetryType,
  getReadableTotalFlowType,
} from '../utils/converter'
import { loadGlobalThresholds, loadSitesThresholds } from 'api/thresholdClient'
import {
  FilterListTwoTone,
  ExpandMoreTwoTone as MoreIcon,
  ExpandLessTwoTone as LessIcon,
  FindReplaceOutlined,
} from '@material-ui/icons'
import {
  DoubleRowTableColumnHeader,
  StyledTableCell,
  DoubleRowTableCell,
  StyledFirstTableCell,
  StatusChip,
} from 'components/misc/Components'
import { idRoute } from '../../App'
import { StatusRange, TelemetryStatus } from 'api/alertservice'
import AssetCard from './AssetCard'
import { Threshold } from '../../api/thresholdClient'
import {
  getMostSevereStatus,
  getStatusesSeverity,
  TotalFlowType,
} from '../sites/utils'
import { Asset } from 'store/Site'
import { getWellStatusRequest } from 'api/commands'

import BatteryNotification from './BatteryNotification'

const styles = (theme: Theme) =>
  createStyles({
    table: {
      paddingBottom: theme.spacing(2.5),
    },
    root: {
      display: 'grid',
      overflow: 'hidden',
      padding: theme.spacing(1),
    },
    container: {
      minWidth: 650,
      paddingBottom: '5%',
      width: '100%',
      overflow: 'auto',
      height: '100%',
    },
    assetFilter: {
      borderRadius: 2,
      padding: 2,
      minWidth: 300,
      maxWidth: 300,
      marginBottom: 20,
      marginRight: 10,
    },
  })

interface Props {
  readonly mySites: Site[]
  readonly setSiteStatus?: ({
    siteId,
    status,
  }: {
    siteId: string
    status: number
  }) => void
  readonly statuses?: { siteId: string; status: number }[]
  readonly siteRoute: string
}

export interface SiteWithTelemetry extends Site {
  latestTelemetry: TelemetryMessage[]
}

interface PropsFromState {
  readonly groups: Group[]
  readonly accessToken?: string
  readonly tenantId: number
  readonly loading?: boolean
  readonly clientName?: string
}

interface WellStatusReqBody {
  wellId: string
  position: number
  deviceId: string
}

interface WellState {
  wellStatus: string
  deviceId: string
  message: string
  siteId: string
  wellId: string
}

const StyledSelectedTableRow = withStyles(() =>
  createStyles({
    selected: {
      backgroundColor: 'rgb(245,245,245) !important',
    },
  })
)(TableRow)

const ASSET_TYPE_SELECT_ITEM_HEIGHT = 48
const ASSET_TYPE_SELECT_ITEM_PADDING_TOP = 8

type AllProps = Props &
  PropsFromState &
  RouteComponentProps &
  WithStyles<typeof styles> &
  SiteContextData

enum TankLevelUnits {
  Feet = 1,
  Barrels = 2,
}

interface Thresholds {
  [key: string]: Map<TelemetryType, Map<string, StatusRange[]>>
}

interface State extends ModelState {
  readonly sitesWithTelemetryPaginated: SiteWithTelemetry[]
  readonly sitesSelectedPage: number
  readonly sitesPages: number
  readonly sitesCount: number
  readonly loadingTable: boolean
  readonly thresholds: Thresholds
  readonly orderBy: number
  readonly orderDesc: boolean
  readonly wellsWithStatus: WellState[]
  assetName: string[]
  readonly isSortingNeddedAfterTableLoaded: boolean
  batteryLevelsByTelemetry: any
}

function compareNumber(a: number, b: number) {
  return a - b
}

function compareStrings(a: string, b: string) {
  return a.localeCompare(b)
}

function compareDate(a: Date, b: Date) {
  return a.valueOf() - b.valueOf()
}

function compareAny<T>(
  a: any | undefined,
  b: any | undefined,
  compare: (a: T, b: T) => number
): number {
  if (
    (a === undefined || Number.isNaN(a)) &&
    (b === undefined || Number.isNaN(b))
  ) {
    return 0
  }

  if (a === undefined || Number.isNaN(a)) {
    return -1
  }

  if (b === undefined || Number.isNaN(b)) {
    return 1
  }

  return compare(a, b)
}

function getLastReportValue(telemetry: TelemetryMessage[]) {
  const sortedTelemetry = telemetry.sort(
    (a, b) => b?.CreationTimeUtc?.valueOf() - a?.CreationTimeUtc?.valueOf()
  )
  const latestTelemetry = sortedTelemetry.length
    ? sortedTelemetry[0]
    : undefined

  return latestTelemetry?.CreationTimeUtc
}

function getLatestTelemetryValueAndTime(
  site: SiteWithTelemetry,
  telemetryType: TelemetryType,
  assetId: string
): [any | undefined, Date | undefined, string | undefined] {
  let telemetry: TelemetryMessage | undefined

  if (telemetryType === TelemetryType.GatewayMetrics) {
    telemetry = site.latestTelemetry.find(
      (t) => t.TelemetryType === telemetryType
    )

    return [
      telemetry?.Payload?.primaryBatteryLevel,
      telemetry?.CreationTimeUtc,
      telemetry?.Payload.unitOfMeasure,
    ]
  }
  if (telemetryType === TelemetryType.PumpControl) {
    telemetry = site.latestTelemetry.find(
      (t) => t.TelemetryType === telemetryType && t.AssetId === assetId
    )
    return [
      telemetry?.Payload?.metaData,
      telemetry?.CreationTimeUtc,
      telemetry?.Payload.unitOfMeasure,
    ]
  }
  telemetry = site.latestTelemetry.find(
    (t) => t.TelemetryType === telemetryType && t.AssetId === assetId
  )

  return [
    telemetry?.Payload?.value,
    telemetry?.CreationTimeUtc,
    telemetry?.Payload.unitOfMeasure,
  ]
}

function getAssetsCount({
  flowMeters,
  tanks,
  wells,
  heaters,
}: SiteWithTelemetry) {
  return flowMeters.length + tanks.length + wells.length + heaters.length
}

const headerCellNameAndSortability: [string, boolean][] = [
  ['Site', true],
  ['Last Report', true],
  ['Summary', true],
  ['Status', true],
  ['Battery Status', true],
]

class MySites extends React.Component<AllProps, State> {
  sitesAll: SiteWithTelemetry[] = []
  sitesFiltered: SiteWithTelemetry[] = []
  constructor(props) {
    super(props)

    this.state = {
      sitesWithTelemetryPaginated: [],
      sitesSelectedPage: 1,
      sitesCount: 0,
      sitesPages: 0,
      loadingTable: false,
      thresholds: {},
      orderBy: this.getLocalStorageOrderByColumnIndex(),
      orderDesc:
        this.getLocalStorageOrderDirection() === TableOrderDirectionType.Desc,
      wellsWithStatus: [],
      assetName: [],
      isSortingNeddedAfterTableLoaded:
        this.getLocalStorageOrderByColumnIndex() ===
        SitesTableOrderByType.Status,
      batteryLevelsByTelemetry: 0,
    }
  }

  private readonly indexToProperty: [
    (s: SiteWithTelemetry) => any,
    (a: any, b: any) => number
  ][] = [
    [(s) => s.name, compareStrings],
    [(s) => getLastReportValue(s.latestTelemetry), compareDate],
    [(s) => getAssetsCount(s), compareNumber],
    [(s) => this.getSiteStatusesSeverity(s), compareNumber],
    [(s) => this.getSiteBatterySeverity(s), compareNumber],
  ]

  private compareSitesWithTelemetry(index: number, orderDesc: boolean) {
    if (index < 0 || index >= this.indexToProperty.length) {
      return undefined
    }

    const [getPayload, compare] = this.indexToProperty[index]

    return (
      site: SiteWithTelemetry,
      anotherSite: SiteWithTelemetry
    ): number => {
      const a = getPayload(site)
      const b = getPayload(anotherSite)
      const result = compareAny(a, b, compare)

      return orderDesc ? -result : result
    }
  }

  private readonly memoizedSites = memoize(
    (
      sites: SiteWithTelemetry[],
      orderBy: number,
      orderDesc: boolean
    ): SiteWithTelemetry[] =>
      sites.sort(this.compareSitesWithTelemetry(orderBy, orderDesc))
  )

  public componentDidMount() {
    this.load()
  }

  public componentDidUpdate(prevProps: AllProps, prevState: State) {
    const { mySites } = this.props
    const siteIds = mySites.map((w) => w.id)
    const siteIdsSet = new Set(siteIds)
    const prevSiteIds = prevProps.mySites.map((w) => w.id)
    const prevSiteIdsSet = new Set(prevSiteIds)

    prevSiteIds.forEach((id) => siteIdsSet.delete(id))
    siteIds.forEach((id) => prevSiteIdsSet.delete(id))

    if (siteIdsSet.size !== 0 || prevSiteIdsSet.size !== 0) {
      this.load()
    }

    const { orderBy, orderDesc, isSortingNeddedAfterTableLoaded } = this.state

    if (orderBy !== prevState.orderBy || orderDesc !== prevState.orderDesc) {
      const sites = this.memoizedSites(this.sitesFiltered, orderBy, orderDesc)
      this.setState({
        loading: false,
        isSortingNeddedAfterTableLoaded: false,
      })
      this.sitesFiltered = sites
      this.FillSites()
    }

    if (isSortingNeddedAfterTableLoaded) {
      this.performSortingAfterTableLoaded()
    }
  }

  private performSortingAfterTableLoaded() {
    const { orderBy, orderDesc } = this.state
    const sites = this.memoizedSites(this.sitesFiltered, orderBy, orderDesc)
    this.setState({
      loading: false,
      isSortingNeddedAfterTableLoaded: false,
    })
    this.sitesFiltered = sites
    this.FillSites()
  }

  public getLocalStorageOrderByColumnIndex() {
    const sitesTableSorting = getSitesTableOrderByColumnObj()
    return sitesTableSorting ? sitesTableSorting.value : -1
  }

  public getLocalStorageOrderDirection() {
    const sitesTableSorting = getSitesTableOrderDirectionObj()
    return sitesTableSorting
      ? sitesTableSorting.value
      : TableOrderDirectionType.Desc
  }

  public render() {
    return (
      <div className={this.props.classes.container}>
        {this.props.loading || this.state.loading ? (
          <LinearProgress />
        ) : (
          this.renderSitesTable()
        )}
      </div>
    )
  }

  private renderSitesTable() {
    const handlePaginationChange = (event, value) => {
      this.FillSites(value)
    }

    return (
      <>
        {this.renderSiteFilter()}
        {this.state.loadingTable && <LinearProgress />}
        <Table stickyHeader={true} className={this.props.classes.table}>
          {this.renderSitesMasterTableHeader()}
          {this.renderSitesMasterTableBody()}
          <TableFooter>
            <Pagination
              count={this.state.sitesPages}
              page={this.state.sitesSelectedPage}
              onChange={handlePaginationChange}
            />
          </TableFooter>
        </Table>
      </>
    )
  }

  private renderSiteFilter() {
    const assets = getAssetFilterArray().sort((a, b) =>
      a.label.localeCompare(b.label)
    )

    const handleChange = (event) => {
      const {
        target: { value },
      } = event
      const assetName = typeof value === 'string' ? value.split(',') : value
      this.setState({
        assetName: assetName,
      })
      this.sitesFiltered = assetName.length
        ? this.filterSites(assetName)
        : this.sitesAll
      this.FillSites()
    }

    const handleClear = () => {
      this.setState({ assetName: [] })
      this.sitesFiltered = this.sitesAll
      this.FillSites()
    }

    return (
      <>
        <Select
          multiple
          className={this.props.classes.assetFilter}
          value={this.state.assetName}
          displayEmpty
          onChange={handleChange}
          renderValue={(selected) => {
            return (
              <span style={{ fontSize: 12 }}>
                <span style={{ color: '#f27a54' }}>Asset Type: </span>
                {(selected as string[]).join(', ')}
              </span>
            )
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight:
                  ASSET_TYPE_SELECT_ITEM_HEIGHT * 9 +
                  ASSET_TYPE_SELECT_ITEM_PADDING_TOP,
                width: 250,
              },
            },
            variant: 'menu',
            getContentAnchorEl: null,
          }}
        >
          {assets.map((asset) => (
            <MenuItem key={asset.key} value={asset.key}>
              <Checkbox
                color="primary"
                checked={this.state.assetName.indexOf(asset.key) > -1}
                size="small"
              />
              <ListItemText primary={asset.label} style={{ fontSize: 12 }} />
            </MenuItem>
          ))}
        </Select>
        <Tooltip title="Clear filters" placement="bottom">
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={handleClear}
            startIcon={<FindReplaceOutlined />}
            id={`clearFiltersButton`}
          >
            Clear
          </Button>
        </Tooltip>
      </>
    )
  }

  private renderSitesMasterTableHeader() {
    const [[firstCellTitle, isSortable], ...restOfHeaderCells] =
      headerCellNameAndSortability
    const FirstCell = (props: TableCellProps) =>
      this.renderStyledHeaderCell(
        firstCellTitle,
        undefined,
        0,
        props,
        !isSortable,
        true
      )

    return (
      <TableHead>
        <TableRow>
          <FirstCell style={{ zIndex: 4 }} />
          {restOfHeaderCells.map((c, i) =>
            this.renderStyledHeaderCell(
              c[0],
              undefined,
              i + 1,
              undefined,
              !c[1]
            )
          )}
          <TableCell
            variant="head"
            style={{
              padding: 0,
              width: theme.spacing(2),
            }}
          />
        </TableRow>
      </TableHead>
    )
  }

  private renderAssetCards(site: SiteWithTelemetry) {
    const GatewayMetricsExists = site.latestTelemetry.some(
      (item) => item.TelemetryType === 9
    )

    return (
      <div
        style={{
          display: 'flex',
          flexFlow: 'row wrap',
          padding: theme.spacing(0, 0, 2, 2),
        }}
      >
        {GatewayMetricsExists &&
          this.renderGatewayMetricsCard(site, TelemetryType.GatewayMetrics)}
        {this.renderSimpleAsset(site, TelemetryType.Vibration, site.vibrations)}
        {this.renderWellCards(site)}
        {this.renderLinePSICards(site)}
        {this.renderSimpleAsset(
          site,
          TelemetryType.StrokesPerMinute,
          site.strokesPerMinute
        )}
        {this.renderSimpleAsset(
          site,
          TelemetryType.KnockoutPressure,
          site.knockoutPressures
        )}
        {this.renderTankCards(site)}
        {this.renderBalanceTankCards(site)}
        {this.renderFlowCards(site)}
        {this.renderSimpleAsset(
          site,
          TelemetryType.TurbineMeter,
          site.turbineMeters
        )}
        {this.renderSimpleAsset(
          site,
          TelemetryType.GenericSensor,
          site.genericSensors
        )}
        {this.renderSimpleAsset(site, TelemetryType.HeaterTemp, site.heaters)}
        {this.renderSimpleAsset(
          site,
          TelemetryType.SeparatorPressure,
          site.separators
        )}
        {this.renderSimpleAsset(
          site,
          TelemetryType.CompressorPressure,
          site.compressors
        )}
        {this.renderSimpleFlareAsset(
          site,
          TelemetryType.FlareStatus,
          site.flaresStatus
        )}
        {this.renderSimplePumpControlAsset(
          site,
          TelemetryType.PumpControl,
          site.pumpControls
        )}
        {}
      </div>
    )
  }

  private thereAreDataLatestTelemtry(site: SiteWithTelemetry) {
    return site.latestTelemetry.length > 0
  }

  private renderLinePSICards(site: SiteWithTelemetry) {
    const sensorBatteryLevel = this.getBatteryLevelByTelemtry(
      site.latestTelemetry,
      TelemetryType.StaticPressure
    )

    return site.staticPressure.map(({ id }, i) => {
      const title = `Line Pressure`
      return (
        <AssetCard key={i} title={title}>
          <TableRow>
            <TableCell>
              {getReadableTelemetryType(TelemetryType.StaticPressure)}
            </TableCell>
            {this.renderTelemetryCell(site, TelemetryType.StaticPressure, id)}
          </TableRow>

          <TableRow>
            <TableCell>
              {getReadableTelemetryType(TelemetryType.StaticTemp)}
            </TableCell>
            {this.renderTelemetryCell(site, TelemetryType.StaticTemp, id)}
          </TableRow>
          {this.batteryLevelField(
            sensorBatteryLevel,
            this.thereAreDataLatestTelemtry(site)
          )}
        </AssetCard>
      )
    })
  }

  private renderWellCards(site: SiteWithTelemetry) {
    return site.wells.map(
      ({ id, name, hasCasing, hasTubing, hasCrank, hasPump }, i) => {
        const title = `Well: ${name ?? id}`

        const { sensorBatteryLevel } =
          site.latestTelemetry.find((item) => item.AssetId === id) || {}

        return (
          <AssetCard
            key={i}
            title={title}
            wellState={this.state.wellsWithStatus[i]?.wellStatus ?? '...'}
          >
            {hasTubing && (
              <TableRow>
                <TableCell>
                  {getReadableTelemetryType(TelemetryType.TubingPressure)}
                </TableCell>
                {this.renderTelemetryCell(
                  site,
                  TelemetryType.TubingPressure,
                  id
                )}
              </TableRow>
            )}
            {hasCasing && (
              <TableRow>
                <TableCell>
                  {getReadableTelemetryType(TelemetryType.CasingPressure)}
                </TableCell>
                {this.renderTelemetryCell(
                  site,
                  TelemetryType.CasingPressure,
                  id
                )}
              </TableRow>
            )}
            {hasCrank && (
              <TableRow>
                <TableCell>
                  {getReadableTelemetryType(TelemetryType.CrankRevolutions)}
                </TableCell>
                {this.renderTelemetryCell(
                  site,
                  TelemetryType.CrankRevolutions,
                  id
                )}
              </TableRow>
            )}
            {hasPump && (
              <TableRow>
                <TableCell>
                  {getReadableTelemetryType(TelemetryType.PumpPressure)}
                </TableCell>
                {this.renderTelemetryCell(site, TelemetryType.PumpPressure, id)}
              </TableRow>
            )}
            {this.batteryLevelField(
              sensorBatteryLevel,
              this.thereAreDataLatestTelemtry(site)
            )}
          </AssetCard>
        )
      }
    )
  }

  private renderTankCards(site: SiteWithTelemetry) {
    return site.tanks.map((tank, i) => {
      const title = `Tank: ${tank.name ?? tank.id}`
      const productionCode = getReadableProductionCode(
        tank.productionCode
      )?.toUpperCase()
      const tag = productionCode ? <b>{productionCode}</b> : undefined
      const latestTelemetry = site.latestTelemetry.find(
        (e) =>
          e.TelemetryType === TelemetryType.TankLevel && e.AssetId === tank.id
      )
      const value = latestTelemetry?.Payload?.value
      const style = this.createTankLevelStatusBackgroundStyle(
        site.id,
        tank.id,
        value
      )

      const batteryLevel = latestTelemetry?.sensorBatteryLevel

      return (
        <AssetCard key={i} title={title} tag={tag}>
          <TableRow>
            <TableCell>
              {getReadableTelemetryType(TelemetryType.TankLevel)}
              ,&nbsp;ft
            </TableCell>
            {this.renderTankLevelTelemetryCell(
              TankLevelUnits.Feet,
              style,
              tank,
              latestTelemetry,
              value
            )}
          </TableRow>
          <TableRow>
            <TableCell>
              {getReadableTelemetryType(TelemetryType.TankLevel)}
              ,&nbsp;bbl
            </TableCell>
            {this.renderTankLevelTelemetryCell(
              TankLevelUnits.Barrels,
              style,
              tank,
              latestTelemetry,
              value
            )}
          </TableRow>

          {this.batteryLevelField(
            batteryLevel,
            this.thereAreDataLatestTelemtry(site)
          )}
        </AssetCard>
      )
    })
  }

  private renderBalanceTankCards(site: SiteWithTelemetry) {
    const sensorBatteryLevel = this.getBatteryLevelByTelemtry(
      site.latestTelemetry,
      TelemetryType.BalancedTank
    )

    return site.BalancedTanks.map((balanceTank, i) => {
      const title = `B. Tanks Level: ${balanceTank.name ?? balanceTank.id}`
      const productionCode = getReadableProductionCode(
        balanceTank.productionCode
      )?.toUpperCase()
      const tag = productionCode ? <b>{productionCode}</b> : undefined
      const latestTelemetry = site.latestTelemetry.find(
        (e) =>
          e.TelemetryType === TelemetryType.BalancedTank &&
          e.AssetId === balanceTank.id
      )
      const value = latestTelemetry?.Payload?.value
      const style = this.createTankLevelStatusBackgroundStyle(
        site.id,
        balanceTank.id,
        value
      )

      return (
        <AssetCard key={i} title={title} tag={tag}>
          <TableRow>
            <TableCell>
              {getReadableTelemetryType(TelemetryType.BalancedTank)}
              ,&nbsp;ft
            </TableCell>
            {this.renderTankLevelTelemetryCell(
              TankLevelUnits.Feet,
              style,
              balanceTank,
              latestTelemetry,
              value,
              TelemetryType.BalancedTank
            )}
          </TableRow>
          <TableRow>
            <TableCell>
              {getReadableTelemetryType(TelemetryType.BalancedTank)}
              ,&nbsp;bbl
            </TableCell>
            {this.renderTankLevelTelemetryCell(
              TankLevelUnits.Barrels,
              style,
              balanceTank,
              latestTelemetry,
              value,
              TelemetryType.BalancedTank
            )}
          </TableRow>
          {this.batteryLevelField(
            sensorBatteryLevel,
            this.thereAreDataLatestTelemtry(site)
          )}
        </AssetCard>
      )
    })
  }

  private renderFlowCards(site: SiteWithTelemetry) {
    const TotalFlowbatteryLevel = site.latestTelemetry.find(
      (item) => item.TelemetryType === 17
    )?.sensorBatteryLevel
    const staticTempbatteryLevel = site.latestTelemetry.find(
      (item) => item.TelemetryType === 7
    )?.sensorBatteryLevel
    const FlowbatteryLevel = site.latestTelemetry.find(
      (item) => item.TelemetryType === 8
    )?.sensorBatteryLevel

    return site.flowMeters.map(({ name, id, isTotalFlow }, i) => {
      if (isTotalFlow) {
        const title = `Total Flow: ${name ?? id}`

        return (
          <AssetCard title={title} key={i}>
            <TableRow>
              <TableCell>
                {getReadableTotalFlowType(TotalFlowType.AccumulatedVolume)}
              </TableCell>
              {this.renderTotalFlowTelemetryCell(
                site,
                TelemetryType.TotalFlow,
                TotalFlowType.AccumulatedVolume,
                id
              )}
            </TableRow>
            <TableRow>
              <TableCell>
                {getReadableTotalFlowType(TotalFlowType.BatteryVolts)}
              </TableCell>
              {this.renderTotalFlowTelemetryCell(
                site,
                TelemetryType.TotalFlow,
                TotalFlowType.BatteryVolts,
                id
              )}
            </TableRow>
            <TableRow>
              <TableCell>
                {getReadableTotalFlowType(TotalFlowType.DiffPressure)}
              </TableCell>
              {this.renderTotalFlowTelemetryCell(
                site,
                TelemetryType.TotalFlow,
                TotalFlowType.DiffPressure,
                id
              )}
            </TableRow>
            <TableRow>
              <TableCell>
                {getReadableTotalFlowType(TotalFlowType.StaticPressure)}
              </TableCell>
              {this.renderTotalFlowTelemetryCell(
                site,
                TelemetryType.TotalFlow,
                TotalFlowType.StaticPressure,
                id
              )}
            </TableRow>
            <TableRow>
              <TableCell>
                {getReadableTotalFlowType(TotalFlowType.Temperature)}
              </TableCell>
              {this.renderTotalFlowTelemetryCell(
                site,
                TelemetryType.TotalFlow,
                TotalFlowType.Temperature,
                id
              )}
            </TableRow>
            <TableRow>
              <TableCell>
                {getReadableTotalFlowType(TotalFlowType.TodayFlowFlowed)}
              </TableCell>
              {this.renderTotalFlowTelemetryCell(
                site,
                TelemetryType.TotalFlow,
                TotalFlowType.TodayFlowFlowed,
                id
              )}
            </TableRow>
            <TableRow>
              <TableCell>
                {getReadableTotalFlowType(TotalFlowType.VolumeFlowRate)}
              </TableCell>
              {this.renderTotalFlowTelemetryCell(
                site,
                TelemetryType.TotalFlow,
                TotalFlowType.VolumeFlowRate,
                id
              )}
            </TableRow>
            <TableRow>
              <TableCell>
                {getReadableTotalFlowType(TotalFlowType.YesterdayVolumeFlowed)}
              </TableCell>
              {this.renderTotalFlowTelemetryCell(
                site,
                TelemetryType.TotalFlow,
                TotalFlowType.YesterdayVolumeFlowed,
                id
              )}
            </TableRow>
            <TableRow>
              <TableCell>
                {getReadableTotalFlowType(TotalFlowType.StaticPressure)}
              </TableCell>
              {this.renderTotalFlowTelemetryCell(
                site,
                TelemetryType.TotalFlow,
                TotalFlowType.StaticPressure,
                id
              )}
            </TableRow>
            {this.batteryLevelTotalFlowField(
              TotalFlowbatteryLevel,
              this.thereAreDataLatestTelemtry(site)
            )}
          </AssetCard>
        )
      } else {
        const title = `Flow meter: ${name ?? id}`
        return (
          <AssetCard title={title} key={i}>
            <TableRow>
              <TableCell>
                {getReadableTelemetryType(TelemetryType.Flow)}
              </TableCell>
              {this.renderTelemetryCell(site, TelemetryType.Flow, id)}
              {this.batteryLevelField(
                FlowbatteryLevel,
                this.thereAreDataLatestTelemtry(site)
              )}
            </TableRow>
            <TableRow>
              <TableCell>
                {getReadableTelemetryType(TelemetryType.StaticTemp)}
              </TableCell>
              {this.renderTelemetryCell(site, TelemetryType.StaticTemp, id)}
              {this.batteryLevelField(
                staticTempbatteryLevel,
                this.thereAreDataLatestTelemtry(site)
              )}
            </TableRow>
          </AssetCard>
        )
      }
    })
  }

  private batteryLevelField(
    value: string | number | undefined,
    thereAreData = true
  ) {
    if (!thereAreData || value === undefined) return

    const typeAlertColor = (value: number): string => {
      if (value <= 20) return '#ff0000'
      if (value <= 40 && value >= 21) return '#FFD146'

      return '#92d050'
    }

    if (!thereAreData) return

    return (
      <TableRow>
        <TableCell>Battery Level</TableCell>
        <TableCell>
          <Typography
            style={{
              color: typeAlertColor(Number(value)),
              fontWeight: 700,
              fontSize: '0.8rem',
            }}
          >
            {thereAreData ? `${value.toString()} %` : 'N/A'}
          </Typography>
        </TableCell>
      </TableRow>
    )
  }

  private batteryLevelTotalFlowField(
    value: string | number | undefined,
    thereAreData = true
  ) {
    if (!thereAreData || value === undefined) return

    const typeAlertColor = (value: number): string => {
      if (value <= 20) return '#ff0000'
      if (value <= 40 && value >= 21) return '#FFD146'

      return '#92d050'
    }

    if (!thereAreData) return

    return (
      <TableRow>
        <TableCell>Gateway Battery Level</TableCell>
        <TableCell>
          <Typography
            style={{
              color: typeAlertColor(Number(value)),
              fontWeight: 700,
              fontSize: '0.8rem',
            }}
          >
            {thereAreData ? `${value.toString()} %` : 'N/A'}
          </Typography>
        </TableCell>
      </TableRow>
    )
  }

  private readonly getBatteryLevelByTelemtry = (
    latestTelInfo: TelemetryMessage[],
    telType: TelemetryType
  ): string | undefined => {
    const { sensorBatteryLevel } =
      latestTelInfo.find((item) => item.TelemetryType === telType) || {}

    return sensorBatteryLevel
  }

  private renderSimpleAsset(
    site: SiteWithTelemetry,
    telemetryType: TelemetryType,
    assets: Asset[]
  ) {
    const sensorBatteryLevel = this.getBatteryLevelByTelemtry(
      site.latestTelemetry,
      telemetryType
    )

    return assets.map(({ id, name, ...rest }, i) => {
      const telemetryName = getReadableTelemetryType(telemetryType)
      const telemetryName1stPArt = telemetryName.split(' ')[0]
      const title = `${telemetryName1stPArt}: ${name ?? id}`

      const { AssetId } =
        site.latestTelemetry.find(
          (item) => item.TelemetryType === telemetryType
        ) || {}

      const isInLatest = AssetId === id

      if (telemetryType === TelemetryType.Vibration) {
        if (site.compressors.length > 0)
          return (
            <AssetCard title={title} key={i}>
              <TableRow>
                <TableCell>{telemetryName}</TableCell>
                {this.renderTelemetryCell(site, telemetryType, id)}
              </TableRow>
              {isInLatest &&
                this.batteryLevelField(
                  sensorBatteryLevel,
                  this.thereAreDataLatestTelemtry(site)
                )}
            </AssetCard>
          )
        else return null
        // }
        // else if (telemetryType === TelemetryType.GatewayMetrics) {
        //  return (
        //    <AssetCard title={ site.GatewayId } key={i}>
        //      {this.batteryLevelField(sensorBatteryLevel, this.thereAreDataLatestTelemtry(site))}
        //    </AssetCard>

        //  )
      } else if (telemetryType === TelemetryType.TurbineMeter) {
        return (
          <AssetCard title={title} key={i}>
            <TableRow>
              <TableCell>{telemetryName}</TableCell>
              {this.renderTelemetryCell(site, telemetryType, id)}
            </TableRow>
            {isInLatest &&
              this.batteryLevelField(
                sensorBatteryLevel,
                this.thereAreDataLatestTelemtry(site)
              )}
          </AssetCard>
        )
      } else {
        return (
          <AssetCard title={title} key={i}>
            <TableRow>
              <TableCell>{telemetryName}</TableCell>
              {this.renderTelemetryCell(site, telemetryType, id)}
            </TableRow>
            {isInLatest &&
              this.batteryLevelField(
                sensorBatteryLevel,
                this.thereAreDataLatestTelemtry(site)
              )}
          </AssetCard>
        )
      }
    })
  }

  private renderGatewayMetricsCard(
    site: SiteWithTelemetry,
    telemetryType: TelemetryType
  ) {
    const gatewayMetrics = site.latestTelemetry.filter(
      (x) => x.TelemetryType === TelemetryType.GatewayMetrics
    )

    return gatewayMetrics.map(({ GatewayId, sensorBatteryLevel }, i) => {
      return (
        <AssetCard title={`GatewayId: ${GatewayId}`} key={i}>
          {this.batteryLevelField(
            sensorBatteryLevel,
            this.thereAreDataLatestTelemtry(site)
          )}
        </AssetCard>
      )
    })
  }

  private renderSimpleFlareAsset(
    site: SiteWithTelemetry,
    telemetryType: TelemetryType,
    assets: Asset[]
  ) {
    const sensorBatteryLevel = this.getBatteryLevelByTelemtry(
      site.latestTelemetry,
      telemetryType
    )

    return assets.map(({ id, name }, i) => {
      const telemetryName = getReadableTelemetryType(telemetryType)
      const telemetryName1stPArt = telemetryName.split(' ')[0]
      const title = `${telemetryName1stPArt}: ${name ?? id}`

      return (
        <AssetCard title={title} key={i}>
          <TableRow>
            <TableCell>{telemetryName}</TableCell>
            {this.renderFlareTelemetryCell(site, telemetryType, id)}
          </TableRow>
          {this.batteryLevelField(
            sensorBatteryLevel,
            this.thereAreDataLatestTelemtry(site)
          )}
        </AssetCard>
      )
    })
  }

  private renderSimplePumpControlAsset(
    site: SiteWithTelemetry,
    telemetryType: TelemetryType,
    assets: Asset[]
  ) {
    return assets.map(({ id, name }, i) => {
      const telemetryName = getReadableTelemetryType(telemetryType)

      const telemetryName1stPArt = telemetryName.split(' ')[0]
      const title = `${telemetryName1stPArt}: ${name ?? id}`

      return (
        <AssetCard title={title} key={i}>
          {this.renderPumpControlTelemetryCell(site, telemetryType, id)}
        </AssetCard>
      )
    })
  }

  private renderStyledHeaderCell(
    title,
    caption,
    index: number,
    props?: TableCellProps,
    nonSortable?: boolean,
    isFirst?: boolean
  ) {
    const { orderBy, orderDesc } = this.state

    const direction =
      orderBy === index ? (orderDesc ? 'desc' : 'asc') : undefined
    const sortDirection = nonSortable ? undefined : direction
    const Cell = isFirst ? StyledFirstTableCell : StyledTableCell

    return (
      <Cell
        {...props}
        key={index}
        sortDirection={sortDirection}
        style={{
          ...props?.style,
          backgroundColor: theme.palette.primary.light,
        }}
        variant="head"
      >
        {(title || caption) && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <TableSortLabel
              active={false}
              hideSortIcon={false}
              style={{
                marginLeft: 'auto',
                visibility: 'hidden',
              }}
            />
            <TableSortLabel
              active={nonSortable ? false : orderBy === index}
              direction={sortDirection}
              onClick={
                nonSortable ? undefined : this.onTableHeaderCellClick(index)
              }
              style={{ marginRight: 'auto' }}
              IconComponent={FilterListTwoTone}
              disabled={nonSortable}
            >
              <DoubleRowTableColumnHeader title={title} caption={caption} />
            </TableSortLabel>
          </div>
        )}
      </Cell>
    )
  }

  private readonly onTableHeaderCellClick = (index: number) => () => {
    const { orderBy, orderDesc } = this.state
    setSitesTableSortingOrderBy(index)
    if (orderBy === index) {
      const orderDirection = orderDesc
        ? TableOrderDirectionType.Asc
        : TableOrderDirectionType.Desc
      setSitesTableSortingOrderDirection(orderDirection)
      this.setState({ orderDesc: !orderDesc })
    } else {
      setSitesTableSortingOrderDirection(TableOrderDirectionType.Asc)
      this.setState({
        orderBy: index,
        orderDesc: false,
      })
    }
  }

  private async getWellsStatus(site: SiteWithTelemetry) {
    try {
      this.setState({
        wellsWithStatus: [],
      })
      const wells: WellStatusReqBody[] = []
      site.wells.forEach((well, i) => {
        wells.push({
          wellId: well.id,
          position: i,
          deviceId: well.iwcDeviceId,
        })
      })
      const requestBody = {
        wells,
        clientName: this.props.clientName,
        siteId: site.id,
      }
      const wellStatus = await getWellStatusRequest(
        this.props.accessToken,
        this.props.tenantId,
        requestBody
      )
      this.setState({
        wellsWithStatus: wellStatus.data,
      })
    } catch (error) {
      console.error(error)
    }
  }

  private filterSites(assetName: string[]): SiteWithTelemetry[] {
    return this.sitesAll.filter((site) => {
      return assetName.some((asset) => {
        if (asset === 'isTotalFlow') {
          if (!site.flowMeters || !site.flowMeters?.length) return false
          return site.flowMeters.some(
            (flowMeter) => flowMeter.isTotalFlow === true
          )
        }

        return site[asset]?.length
      })
    })
  }

  private RenderBatteryNotificationComponent(
    site: SiteWithTelemetry,
    style?: React.CSSProperties
  ) {
    return (
      <StyledTableCell style={style}>
        <BatteryNotification site={site} />
      </StyledTableCell>
    )
  }

  private renderSitesMasterTableBody() {
    const data = this.state.sitesWithTelemetryPaginated

    const GatewayMetricsAsSensor: GatewayMetricsAsSensor[] = [
      {
        id: '',
        name: '',
        telemetryTypeFormula: '',
      },
    ]

    const infoPlusGatewayMetrics = data.map(
      (item) => ({ ...item, GatewayMetricsAsSensor } as SiteWithTelemetry)
    )

    return (
      <TableBody>
        {infoPlusGatewayMetrics.map((site) => {
          if (!this.props.mySites.some((e) => e.id === site.id)) {
            return null
          }
          const selected = site.id === this.props.selectedSiteId
          const masterCellStyle: React.CSSProperties | undefined = selected
            ? { borderBottom: 0 }
            : undefined

          return (
            <React.Fragment key={site.id}>
              <StyledSelectedTableRow
                hover={true}
                onClick={() => {
                  this.onSelectedSiteChange(site.id)
                }}
                selected={selected}
              >
                {this.renderSiteCell(site, selected, masterCellStyle)}
                {this.renderLastReportCell(site, masterCellStyle, 'center')}
                {this.renderSummaryCell(site, masterCellStyle)}
                {this.renderStatusCell(site, masterCellStyle)}
                {this.RenderBatteryNotificationComponent(site, masterCellStyle)}
                {this.renderChevronCell(selected, masterCellStyle)}
              </StyledSelectedTableRow>
              <StyledSelectedTableRow
                hover={true}
                style={{ verticalAlign: 'top' }}
                onClick={() => this.onSelectedSiteChange(site.id)}
                selected={selected}
              >
                <TableCell
                  colSpan={headerCellNameAndSortability.length + 1}
                  style={{
                    padding: 0,
                    borderBottom: selected ? undefined : 0,
                  }}
                >
                  <Collapse
                    in={selected}
                    unmountOnExit={true}
                    timeout="auto"
                    // onEnter={() => this.getWellsStatus(site)}
                  >
                    {this.renderAssetCards(site)}
                  </Collapse>
                </TableCell>
              </StyledSelectedTableRow>
            </React.Fragment>
          )
        })}
      </TableBody>
    )
  }

  private renderLastReportCell(
    site: SiteWithTelemetry,
    style?: React.CSSProperties,
    align?: TableCellProps['align'],
    inDetails?: boolean
  ) {
    const lastReportedValue = getLastReportValue(site.latestTelemetry)
    const title = inDetails ? (
      <React.Fragment>
        Last Report:
        <br />
        {this.formatDateToFullAbsolute(lastReportedValue)}
      </React.Fragment>
    ) : (
      this.formatDateToFullAbsolute(lastReportedValue)
    )

    return (
      <Tooltip
        title={lastReportedValue?.toLocaleString() ?? ''}
        placement="top"
        TransitionComponent={Zoom}
      >
        <DoubleRowTableCell
          align={align}
          first={title}
          second={this.formatDateToRelative(lastReportedValue)}
          style={style}
        />
      </Tooltip>
    )
  }

  private renderSiteCell(
    site: SiteWithTelemetry,
    selected?: boolean,
    style?: React.CSSProperties
  ) {
    const siteId = (
      <ReactScrollIntoViewIfNeeded active={selected}>
        {site.id}
      </ReactScrollIntoViewIfNeeded>
    )

    return (
      <DoubleRowTableCell
        first={this.renderSiteLink(site)}
        second={siteId}
        style={style}
      />
    )
  }

  private getStatus(
    siteId: string,
    telemetryType: TelemetryType,
    assetId: string,
    value?: number
  ) {
    // no thresholds for CrankRevolutions telemetry
    if (telemetryType === TelemetryType.CrankRevolutions) {
      return TelemetryStatus.Undefined
    }

    const thresholds = this.getStatusRanges(siteId, telemetryType, assetId)
    const status = this.calculateStatus(thresholds, value)

    return status
  }

  private getStatusRanges(
    siteId: string,
    telemetryType: TelemetryType,
    assetId: string
  ) {
    return this.state.thresholds[siteId]?.get(telemetryType)?.get(assetId)
  }

  private renderTelemetryCell(
    site: SiteWithTelemetry,
    telemetryType: TelemetryType,
    assetId: string
  ) {
    const tooltipFormatter = (date, _) => date?.toLocaleString()
    const [value, creationTimeUtc, unitOfMeasure] =
      getLatestTelemetryValueAndTime(site, telemetryType, assetId)

    const status = this.getStatus(site.id, telemetryType, assetId, value)
    const style: React.CSSProperties =
      status > TelemetryStatus.Green
        ? {
            backgroundColor: getStatusLensColor(status, 0.3),
          }
        : {}

    return (
      <Tooltip
        title={tooltipFormatter(creationTimeUtc, value) ?? ''}
        placement="top"
        TransitionComponent={Zoom}
      >
        <TableCell align="center" style={style}>
          {telemetryType !== TelemetryType.FlareStatus
            ? formatValue(telemetryType, value, unitOfMeasure)
            : this.renderFlareStatusText(value)}
        </TableCell>
      </Tooltip>
    )
  }

  private renderFlareTelemetryCell(
    site: SiteWithTelemetry,
    telemetryType: TelemetryType,
    assetId: string
  ) {
    const tooltipFormatter = (date, _) => date?.toLocaleString()
    const [value, creationTimeUtc] = getLatestTelemetryValueAndTime(
      site,
      telemetryType,
      assetId
    )
    const status = this.getStatus(site.id, telemetryType, assetId, value)
    const style: React.CSSProperties =
      status > TelemetryStatus.Green
        ? {
            backgroundColor: getStatusLensColor(status, 0.3),
          }
        : {}

    return (
      <Tooltip
        title={tooltipFormatter(creationTimeUtc, value) ?? ''}
        placement="top"
        TransitionComponent={Zoom}
      >
        <TableCell align="center" style={style}>
          {telemetryType !== TelemetryType.FlareStatus
            ? formatValue(telemetryType, value)
            : this.renderFlareStatusText(value)}
        </TableCell>
      </Tooltip>
    )
  }

  private renderPumpControlTelemetryCell(
    site: SiteWithTelemetry,
    telemetryType: TelemetryType,
    assetId: string
  ) {
    const tooltipFormatter = (date, _) => date?.toLocaleString()
    const [value, creationTimeUtc] = getLatestTelemetryValueAndTime(
      site,
      telemetryType,
      assetId
    )

    if (!value) {
      return <TableCell align="center">N/A</TableCell>
    }

    const status = this.getStatus(site.id, telemetryType, assetId, value)
    const style: React.CSSProperties =
      status > TelemetryStatus.Green
        ? {
            backgroundColor: getStatusLensColor(status, 0.3),
          }
        : {}

    return (
      <>
        {Object.entries(value).map(([key, val]) => (
          <React.Fragment key={key}>
            <TableRow>
              <TableCell>{key}</TableCell>
              <Tooltip
                title={tooltipFormatter(creationTimeUtc, val) ?? ''}
                placement="top"
                TransitionComponent={Zoom}
              >
                <TableCell align="left">{val}</TableCell>
              </Tooltip>
            </TableRow>
          </React.Fragment>
        ))}
      </>
    )
  }

  private renderPumpControlText(value) {
    if (value === undefined || typeof value !== 'number') {
      return <span>N/A</span>
    }
    if (value === 0) {
      return (
        <span
          style={{
            color: getStatusLensColor(TelemetryStatus.Red),
            fontWeight: 'bold',
          }}
        >
          OFF
        </span>
      )
    } else {
      return (
        <span
          style={{
            color: getStatusLensColor(TelemetryStatus.Green),
            fontWeight: 'bold',
          }}
        >
          ON
        </span>
      )
    }
  }

  private renderFlareStatusText(value) {
    if (value === undefined || typeof value !== 'number') {
      return <span>N/A</span>
    }
    if (value === 0) {
      return (
        <span
          style={{
            color: getStatusLensColor(TelemetryStatus.Red),
            fontWeight: 'bold',
          }}
        >
          OFF
        </span>
      )
    } else {
      return (
        <span
          style={{
            color: getStatusLensColor(TelemetryStatus.Green),
            fontWeight: 'bold',
          }}
        >
          ON
        </span>
      )
    }
  }

  private renderTotalFlowTelemetryCell(
    site: SiteWithTelemetry,
    telemetryType: TelemetryType,
    totalFlowType: TotalFlowType,
    assetId: string
  ) {
    const tooltipFormatter = (date, _) => date?.toLocaleString()
    let [value, creationTimeUtc] = getLatestTelemetryValueAndTime(
      site,
      telemetryType,
      assetId
    )

    if (totalFlowType === TotalFlowType.AccumulatedVolume)
      value = value?.AccumulatedVolume || 0
    if (totalFlowType === TotalFlowType.BatteryVolts)
      value = value?.BatteryVolts || 0
    if (totalFlowType === TotalFlowType.DiffPressure)
      value = value?.DiffPressure || 0
    if (totalFlowType === TotalFlowType.StaticPressure)
      value = value?.StaticPressure || 0
    if (totalFlowType === TotalFlowType.Temperature)
      value = value?.Temperature || 0
    if (totalFlowType === TotalFlowType.TodayFlowFlowed)
      value = value?.TodayVolumeFlowed || 0
    if (totalFlowType === TotalFlowType.VolumeFlowRate)
      value = value?.VolumeFlowRate || 0
    if (totalFlowType === TotalFlowType.YesterdayVolumeFlowed)
      value = value?.YesterdayVolumeFlowed || 0

    const status = this.getStatus(site.id, telemetryType, assetId, value)
    const style: React.CSSProperties =
      status > TelemetryStatus.Green
        ? {
            backgroundColor: getStatusLensColor(status, 0.3),
          }
        : {}

    return (
      <Tooltip
        title={tooltipFormatter(creationTimeUtc, value) ?? ''}
        placement="top"
        TransitionComponent={Zoom}
      >
        <TableCell align="left" style={style}>
          {formatValueTotalFlow(totalFlowType, value)}
        </TableCell>
      </Tooltip>
    )
  }

  private renderTankLevelTelemetryCell(
    units: TankLevelUnits,
    tankLevelStatusBackgroundStyle?: React.CSSProperties,
    tank?: Tank | BalancedTank,
    latestTelemetry?: TelemetryMessage,
    value?,
    telemetryType?: TelemetryType,
    formatTooltip?: (Date?, any?) => NonNullable<React.ReactNode>
  ) {
    const actualTankLevel =
      units === TankLevelUnits.Feet
        ? formatValue(TelemetryType.TankLevel, value)
        : telemetryType === TelemetryType.BalancedTank
        ? this.formatSumTankLevelBarrels(tank as BalancedTank, latestTelemetry!)
        : this.formatActualTankLevelBarrels(value, tank)
    const capacityTankLevel =
      units === TankLevelUnits.Feet
        ? this.formatCapacityTankLevelFeet(
            telemetryType === TelemetryType.BalancedTank
              ? (tank as BalancedTank).balancedHeight
              : tank?.height
          )
        : telemetryType === TelemetryType.BalancedTank
        ? (tank as BalancedTank).totalCapacity
        : tank?.capacity
    const tooltipFormatter =
      formatTooltip ?? ((date, _) => date?.toLocaleString())

    return (
      <Tooltip
        title={tooltipFormatter(latestTelemetry?.CreationTimeUtc, value) ?? ''}
        placement="top"
        TransitionComponent={Zoom}
      >
        <TableCell style={tankLevelStatusBackgroundStyle}>
          {value ? actualTankLevel : '-'}
          &nbsp;
          {value ? `of ${capacityTankLevel}` : ''}
        </TableCell>
      </Tooltip>
    )
  }

  private renderSummaryCell(
    {
      flowMeters,
      tanks,
      BalancedTanks,
      wells,
      heaters,
      separators,
      compressors,
      turbineMeters,
      genericSensors,
      staticPressure,
      strokesPerMinute,
      vibrations,
      knockoutPressures,
      flaresStatus,
      GatewayMetricsAsSensor,
      pumpControls,
    }: SiteWithTelemetry,
    style?: React.CSSProperties
  ) {
    let assetCount =
      flowMeters.length +
      tanks.length +
      BalancedTanks.length +
      wells.length +
      heaters.length +
      separators.length +
      turbineMeters.length +
      genericSensors.length +
      staticPressure.length +
      strokesPerMinute.length +
      knockoutPressures.length +
      pumpControls.length +
      flaresStatus.length

    if (compressors.length) {
      assetCount = assetCount + compressors.length + vibrations.length
    }
    return (
      <StyledTableCell align="center" style={style}>
        {assetCount === 0 && 'No assets'}
        {assetCount === 1 && '1 asset'}
        {assetCount > 1 && `${assetCount} assets`}
      </StyledTableCell>
    )
  }

  private renderStatusCell(
    site: SiteWithTelemetry,
    style?: React.CSSProperties
  ) {
    const assetStatuses: [string, TelemetryStatus][] = [
      ...this.getAssetStatuses(site),
    ]

    const allOk = assetStatuses.every(
      ([_, status]) =>
        status === TelemetryStatus.Green || status === TelemetryStatus.Undefined
    )

    const statuses = assetStatuses
      .map(([_, status]) => status)
      .sort((t1, t2) => t2 - t1)
      .map((status) => ({ siteId: site.id, status }))

    if (
      this.props.setSiteStatus &&
      statuses[0]?.status > 0 &&
      !this.props.statuses?.find(
        (s) =>
          s.siteId === statuses[0]?.siteId && s.status === statuses[0]?.status
      )
    ) {
      this.props.setSiteStatus(statuses[0])
    }

    return (
      <StyledTableCell style={style}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            overflowX: 'scroll',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexFlow: 'row wrap',
              flexShrink: 1,
              flexGrow: 0,
              justifyContent: 'space-evenly',
            }}
          >
            {allOk ? (
              <StatusChip label="OK" status={TelemetryStatus.Green} />
            ) : (
              assetStatuses
                .filter(([_, status]) => status >= TelemetryStatus.Yellow)
                .map(([asset, status], i) => (
                  <StatusChip key={i} label={asset} status={status} />
                ))
            )}
          </div>
        </div>
      </StyledTableCell>
    )
  }

  private *getAssetStatuses(
    site: SiteWithTelemetry
  ): Generator<[string, TelemetryStatus]> {
    const getAssetStatus = (
      name: string,
      telemetryTypes: TelemetryType[],
      asset: { id: string; name?: string },
      tag?: string
    ): [string, TelemetryStatus] => {
      const statuses = telemetryTypes.map((telemetryType) => {
        const [value] = getLatestTelemetryValueAndTime(
          site,
          telemetryType,
          asset.id
        )
        const status = this.getStatus(site.id, telemetryType, asset.id, value)

        return status
      })

      const t = tag ? ` [${tag}]` : ''
      return [
        `${name}: ${asset.name ?? asset.id}${t}`,
        getMostSevereStatus(statuses),
      ]
    }

    for (const well of site.wells) {
      yield getAssetStatus(
        'Well',
        [
          ...(well.hasCasing ? [TelemetryType.CasingPressure] : []),
          ...(well.hasTubing ? [TelemetryType.TubingPressure] : []),
          ...(well.hasCrank ? [TelemetryType.CrankRevolutions] : []),
          ...(well.hasPump ? [TelemetryType.PumpPressure] : []),
        ],
        well
      )
    }

    for (const tank of site.tanks) {
      yield getAssetStatus(
        'Tank',
        [TelemetryType.TankLevel],
        tank,
        getReadableProductionCode(tank.productionCode)?.toUpperCase()
      )
    }

    for (const flowMeter of site.flowMeters) {
      yield getAssetStatus('Flow meter', [TelemetryType.Flow], flowMeter)
    }

    for (const heater of site.heaters) {
      yield getAssetStatus('Heater', [TelemetryType.HeaterTemp], heater)
    }
  }

  private renderChevronCell(selected: boolean, style?: React.CSSProperties) {
    const Icon = (props: SvgIconProps) =>
      selected ? <LessIcon {...props} /> : <MoreIcon {...props} />

    return (
      <TableCell
        style={{
          verticalAlign: 'top',
          cursor: 'pointer',
          padding: 0,
          ...style,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Icon
            style={{
              opacity: 0.25,
              margin: theme.spacing(3, 2, 0, 0),
            }}
          />
        </div>
      </TableCell>
    )
  }

  private createTankLevelStatusBackgroundStyle(
    siteId: string,
    assetId: string,
    value: any
  ): React.CSSProperties | undefined {
    const statusRanges = this.getStatusRanges(
      siteId,
      TelemetryType.TankLevel,
      assetId
    )
    const status = this.calculateStatus(statusRanges, value)

    return status && status !== TelemetryStatus.Green
      ? {
          backgroundColor: getStatusLensColor(status, 0.3),
        }
      : undefined
  }

  private formatActualTankLevelBarrels(
    tankLevelHeightInch,
    tank: Tank | undefined
  ): string | undefined {
    if (!tank) {
      return undefined
    }

    const tankLevelBbl = inchToBbl(
      tankLevelHeightInch,
      tank.height,
      tank.capacity
    )
    return tankLevelBbl.toFixed(2)
  }

  private formatSumTankLevelBarrels(
    tank: BalancedTank | undefined,
    latestTelemetry: TelemetryMessage
  ): string | undefined {
    if (!tank) {
      return undefined
    }

    const activeTanks = tank.tanks.filter((tank) => tank.isInactive === false)
    const tankLevelSumArray = activeTanks.map((tank) => {
      const tankTelemetry =
        latestTelemetry?.Payload.telemetryValuePerTanks.find(
          (reading) => reading.tankId === tank.id
        ) ?? null
      return inchToBbl(tankTelemetry?.value ?? 0, tank.height, tank.capacity)
    })
    tankLevelSumArray.push(
      inchToBbl(latestTelemetry?.Payload.value ?? 0, tank.height, tank.capacity)
    )
    const totalTankLevelBbl = tankLevelSumArray.reduce(
      (partialSum, a) => partialSum + a,
      0
    )

    return totalTankLevelBbl.toFixed(2)
  }

  private formatCapacityTankLevelFeet(tankLevelHeightFeet) {
    const [heightFt, heightIn] = feetToFeetAndInches(tankLevelHeightFeet)

    return `${heightFt.toFixed()}' ${heightIn.toFixed(1)}"`
  }

  private calculateStatus(
    thresholds?: StatusRange[],
    value?: number
  ): TelemetryStatus {
    if (!thresholds?.length || (!value && value !== 0)) {
      return TelemetryStatus.Undefined
    }

    for (const th of thresholds) {
      if (value >= th.minThreshold && value < th.maxThreshold) {
        return th.status
      }
    }

    return TelemetryStatus.Red
  }

  private readonly onSelectedSiteChange = (selectedSiteId?: string) => {
    this.props.setSelectedSiteId!(true, selectedSiteId)
  }

  private renderSiteLink(site: SiteWithTelemetry) {
    const route = this.props.siteRoute + idRoute

    const assetStatuses: [string, TelemetryStatus][] = [
      ...this.getAssetStatuses(site),
    ]

    const statuses = assetStatuses
      .map(([_, status]) => status)
      .sort((t1, t2) => t2 - t1)
      .map((status) => ({ siteId: site.id, status }))

    const path = `${generatePath(route, { id: site.id })}?status=${
      statuses[0]?.status ?? 0
    }`

    return (
      <Link component={RouterLink} to={path}>
        {site.name || site.id}
      </Link>
    )
  }

  private formatDateToRelative(date?: Date) {
    if (!date) {
      return ''
    }
    const m = moment(date)
    return m.fromNow()
  }

  private formatDateToFullAbsolute(date?: Date) {
    if (!date) {
      return 'never'
    }
    const m = moment(date)
    return m.isSame(moment(), 'day') ? m.format('h:mm A') : m.format('MMMM D')
  }

  private async load() {
    const { accessToken, tenantId, mySites } = this.props

    if (!accessToken || !tenantId) {
      return
    }

    const siteIds = mySites.map((w) => w.id)

    if (!siteIds.length) {
      return
    }
    // Load latest tlemetry from all sites
    this.setState({ loadingTable: true, sitesWithTelemetryPaginated: [] })
    const sitesResult = await this.loadLatestTelemetry(
      accessToken,
      tenantId.toString(),
      siteIds,
      mySites
    )
    this.sitesAll = sitesResult
    this.sitesFiltered = this.sitesAll
    this.FillSites()
    this.setState({ loadingTable: false })
    this.loadThresholds(accessToken, tenantId.toString(), siteIds)
  }

  private async loadLatestTelemetry(
    accessToken: string,
    tenantId: string,
    siteIds: string[],
    sites: Site[]
  ) {
    this.setState({ loading: true })
    const sitesResult: SiteWithTelemetry[] = sites.map((site) => ({
      ...site,
      latestTelemetry: [],
    }))
    const result = await getLatestTelemetry(accessToken, tenantId, siteIds)

    if (!(result instanceof Error)) {
      sitesResult.forEach((site) => {
        const telemetry = result.filter((e) => e.SiteId === site.id)
        site.latestTelemetry = telemetry
      })
      const { orderBy, orderDesc } = this.state
      const memoizedSites = this.memoizedSites(sitesResult, orderBy, orderDesc)
      this.setState({
        loading: false,
      })
      return memoizedSites
    } else {
      this.setState({
        loading: false,
        error: result.message,
      })
      this.sitesAll = sitesResult
      this.sitesFiltered = this.sitesAll
      this.FillSites()
      return sitesResult
    }
  }

  private FillSites(selectedPage = 1) {
    const sites = this.sitesFiltered
    const pageSize = 15
    const sitesCount = sites.length
    const pages = Math.ceil(sitesCount / pageSize)
    const paginatedSites = sites.slice(
      (selectedPage - 1) * pageSize,
      selectedPage * pageSize
    )
    this.setState({
      sitesWithTelemetryPaginated: paginatedSites,
      sitesSelectedPage: selectedPage,
      sitesPages: pages,
      sitesCount: sitesCount,
    })
  }

  private async loadThresholds(
    accessToken: string,
    tenantId: string,
    siteIds: string[]
  ) {
    const globalThresholds = await loadGlobalThresholds(accessToken, tenantId)
    const alertThresholds = await loadSitesThresholds(
      accessToken,
      tenantId,
      siteIds
    )
    const siteWithThresholds = alertThresholds.map((w) => w[0])
    const siteWithoutThresholds = siteIds.filter(
      (id) => !siteWithThresholds.includes(id)
    )
    const siteWithGlobalThresholds: [string, Threshold[]][] =
      siteWithoutThresholds.map((id) => [id, [globalThresholds]])
    const siteThresholds = alertThresholds.concat(siteWithGlobalThresholds)
    const thresholds = siteThresholds.reduce((ths, [siteId, siteThs]) => {
      const telemetryTypeAndAssetToThresholds = new Map<
        TelemetryType,
        Map<string, StatusRange[]>
      >()

      for (const threshold of siteThs) {
        const assetId = threshold.AssetId!

        for (const [telemetryType, statusRanges] of threshold.thresholds) {
          if (!telemetryTypeAndAssetToThresholds.has(telemetryType)) {
            telemetryTypeAndAssetToThresholds.set(
              telemetryType,
              new Map<string, StatusRange[]>()
            )
          }

          const assetToThresholds =
            telemetryTypeAndAssetToThresholds.get(telemetryType)!

          assetToThresholds.set(assetId, statusRanges)
        }
      }

      return {
        ...ths,
        [siteId]: telemetryTypeAndAssetToThresholds,
      }
    }, {} as Thresholds)

    this.setState({ thresholds })
  }

  private getSiteStatusesSeverity(site: SiteWithTelemetry) {
    const getAssetStatus = (
      telemetryTypes: TelemetryType[],
      asset: { id: string }
    ) => {
      const statuses = telemetryTypes.map((telemetryType) => {
        const [value] = getLatestTelemetryValueAndTime(
          site,
          telemetryType,
          asset.id
        )
        const status = this.getStatus(site.id, telemetryType, asset.id, value)

        return status
      })

      return getStatusesSeverity(statuses)
    }

    let severity = 0

    for (const well of site.wells) {
      severity += getAssetStatus(
        [
          TelemetryType.TubingPressure,
          TelemetryType.CasingPressure,
          ...(well.hasCrank ? [TelemetryType.CrankRevolutions] : []),
          ...(well.hasPump ? [TelemetryType.PumpPressure] : []),
        ],
        well
      )
    }

    for (const tank of site.tanks) {
      severity += getAssetStatus([TelemetryType.TankLevel], tank)
    }

    for (const flowMeter of site.flowMeters) {
      severity += getAssetStatus([TelemetryType.Flow], flowMeter)
    }

    return severity
  }

  private getSiteBatterySeverity(site: SiteWithTelemetry) {
    const batteryTelemetry: Array<number> = []

    site.latestTelemetry.forEach((element) => {
      if (+element.sensorBatteryLevel < 20)
        batteryTelemetry.push(+element.sensorBatteryLevel)
    })

    let severity = 0

    if (batteryTelemetry.length === 0) severity = 100
    else severity = Math.min(...batteryTelemetry)
    return severity
  }
}

const mapStateToProps = ({
  groups,
  oidc,
  multitenantUser,
  appConfig,
}: AppState): PropsFromState => {
  return {
    groups: groups.groups,

    accessToken: multitenantUser.accessToken,
    tenantId: multitenantUser.tenants?.find((t) => t.selected)?.id || 0,
    loading: groups.loading,
    clientName: appConfig.clientName,
  }
}

export default withRouter(
  withStyles(styles)(connect(mapStateToProps)(withSiteContext(MySites)))
)
