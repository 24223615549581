import { TelemetryStatus } from 'api/alertservice'

type Nullable<T> = {
  [P in keyof T]: T[P] | null
}

export function getStatusLensColor(status?: TelemetryStatus, opacity?: number) {
  function getColor() {
    switch (status) {
      case TelemetryStatus.Green:
        return '#7CB342'
      case TelemetryStatus.Yellow:
        return '#FDD835'
      case TelemetryStatus.Red:
        return '#F44336'
      default:
        return '#BDBDBD'
    }
  }

  const color = getColor()

  if (opacity === undefined) {
    return color
  }

  const correctOpacity = Math.round(Math.min(Math.max(opacity * 255.0, 0), 255))
    .toString(16)
    .toUpperCase()

  return `${color}${correctOpacity}`
}

export function deepCopy(array: any[]) {
  return JSON.parse(JSON.stringify(array))
}

export function deepCopyItem(item: any) {
  return JSON.parse(JSON.stringify(item))
}

export function nameOf<T>(name: keyof T) {
  return name
}

export function getAssetFilterArray() {
  return [
    {
      key: 'flowMeters',
      label: 'Flow Meter',
    },
    {
      key: 'isTotalFlow',
      label: 'Total Flow',
    },
    {
      key: 'tanks',
      label: 'Tanks',
    },
    {
      key: 'BalancedTanks',
      label: 'Balanced Tanks',
    },
    {
      key: 'wells',
      label: 'Wells',
    },
    {
      key: 'heaters',
      label: 'Heaters',
    },
    {
      key: 'separators',
      label: 'Separators',
    },
    {
      key: 'compressors',
      label: 'Compressors',
    },
    {
      key: 'turbineMeters',
      label: 'Turbine',
    },
    {
      key: 'staticPressure',
      label: 'Line Pressure',
    },
    {
      key: 'strokesPerMinute',
      label: 'Strokes',
    },
    {
      key: 'vibrations',
      label: 'Vibration',
    },
    {
      key: 'knockoutPressures',
      label: 'Knockout',
    },
    {
      key: 'flaresStatus',
      label: 'Flare Status',
    },
    {
      key: 'pumpControls',
      label: 'Pump Control',
    },
    {
      key: 'genericSensors',
      label: 'Generic',
    },
  ]
}
